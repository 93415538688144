<template>
  <div class="search-input-wrapp">
    <input
      ref="inputSearch"
      class="search-input-wrapp__input"
      placeholder="Поиск по сайту"
      :value="searchValue"
      @input="setValue($event.target?.value)"
      @keyup.enter="search"
    />
    <SvgAsset
      class="search-input-wrapp__icon"
      :class="iconClass"
      :content="icon.content"
      @click="clickIcon"
    />
  </div>
</template>

<script lang="ts">
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import closeIconContent from '~/assets/images/close.svg?raw';
  import searchIconContent from '~/assets/images/search.svg?raw';

  export default defineNuxtComponent({
    name: 'SearchInput',

    components: {
      SvgAsset,
    },

    props: {
      autoFocus: {
        type: Boolean,
        default: false,
      },

      syncFromQuery: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        searchValue: (this.syncFromQuery ? useRoute().query.s || '' : '') as string,
        isFirstEdit: true,
      };
    },

    computed: {
      icon() {
        return this.searchValue
          ? {
              content: closeIconContent,
              value: 'clear',
            }
          : {
              content: searchIconContent,
              value: 'search',
            };
      },
      iconClass(): Record<string, boolean> {
        return {
          'search-input-wrapp__icon-clear': this.icon.value === 'clear',
        };
      },
    },

    watch: {
      '$route.query.s'(query: string) {
        if (this.syncFromQuery) {
          this.searchValue = query;
          this.$emit('search', query);
        }
      },
    },

    mounted() {
      if (this.autoFocus) {
        this.inputFocus();
      }
    },

    methods: {
      clickIcon() {
        if (!this.searchValue) {
          return;
        }

        this.searchValue = '';
        this.inputFocus();

        this.$sendYandexMetrika({
          level1: 'Событие_Поиск',
          level6: 'Очистка запроса',
        });
      },

      inputFocus(): void {
        const inputSearch = this.$refs.inputSearch as HTMLElement;
        inputSearch.focus();
      },

      setValue(value: string) {
        this.searchValue = value.trim();

        if (this.isFirstEdit) {
          this.$sendYandexMetrika({
            level1: 'Событие_Поиск',
            level6: 'Ввод запроса',
          });
        }

        this.isFirstEdit = false;
      },

      search() {
        if (!this.searchValue) {
          return;
        }

        this.$sendYandexMetrika({
          level1: 'Событие_Поиск',
          level6: 'Отправка запроса',
        });

        let { href } = useRouter().resolve({
          name: 'search',
          query: { s: this.searchValue },
        });

        // Нужно для консистентности, чтобы сохранить / в url
        if (href[href.indexOf('?') - 1] !== '/') {
          href = href.replace('?', '/?');
        }

        if (useRoute().name === 'search') {
          useRouter().push(href);
          this.$emit('search', this.searchValue);
        } else {
          location.href = href;
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  .search-input-wrapp {
    width: 100%;
    position: relative;
    :deep(.svg-asset) {
      svg {
        height: 25px;
        width: 25px;
      }
    }

    &__icon {
      position: absolute;
      right: 12px;
      height: 25px;
      width: 25px;
      top: 50%;
      transform: translateY(-50%);

      &-clear {
        @include hover {
          :deep() {
            path {
              fill: #000;
            }
          }
          cursor: pointer;
        }
      }
    }

    &__input {
      border-radius: 2px;
      width: 100%;
      height: var(--search-input-height, 40px);
      padding: 0 40px 0 16px;
      color: #000;
      border: 1px solid #e7e7e7;

      font-family: $mainFont;
      font-size: 16px;
      line-height: 24px;

      &::placeholder {
        color: #777474;
      }
    }
  }
</style>
